<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Case Workers</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>Case Workers</span></a
        >
      </span>
    </div>
    <div>
      <v-row>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search User"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >
            <template #label>
              <i class="fas fa-search"></i> Search Case Worker
            </template>
          </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        ></v-row
      >
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
        striped
        hover
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(payersId)="row">
          {{ payerNames(row.item.payersId) }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <b-modal size="xl" id="caseWorker" centered no-close-on-backdrop>
      <template slot="modal-title">Case Worker</template>
      <v-container data-app fluid>
        <div v-if="this.id" class="page-title pageheading" style="height: 50px">
          <span class="float-right green-btn">
            <a
              v-if="isEditAble"
              class="btn btn-success mr-3"
              @click="editClientinfo"
            >
              <i class="far fa-eye edit_btn"></i><span>Edit</span>
            </a>
            <a v-else class="btn btn-success mr-3" @click="ok()">
              <i class="far fa-eye edit_btn"></i>
              <span>
                Update
                <span v-if="isSending">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </span>
              </span>
            </a>
          </span>
        </div>
        <v-row align="center">
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.fName.$error }"
          >
            <v-text-field
              v-model.trim="$v.fName.$model"
              :disabled="isEditAble"
              label="First Name "
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.fName.$error">
              <span v-if="$v.fName.$error">First name is required</span>
            </div>
          </div>
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.lName.$error }"
          >
            <v-text-field
              v-model.trim="$v.lName.$model"
              label="Last Name "
              :disabled="isEditAble"
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.lName.$error">
              <span v-if="$v.lName.$error">Last name is required</span>
            </div>
          </div>
          <div
            class="form-group col-md-12 mb-form"
            :class="{ invalid: $v.payersId.$error }"
          >
            <v-select
              v-model.trim="$v.payersId.$model"
              :items="PayersList"
              item-text="companyName"
              :disabled="isEditAble"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Payer"
              persistent-hint
            ></v-select>
            <div class="invalid-feedback" v-if="$v.payersId.$error">
              <span v-if="$v.payersId.$error">Payers is required</span>
            </div>
          </div>
        </v-row>
      </v-container>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="id" size="lg" variant="success" @click="ok()">
          Update
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular
          ></span>
        </b-button>
        <b-button v-else size="lg" variant="success" @click="ok()">
          Create
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(caseWorkerId)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span>
          </a>
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";

import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    fName: {
      required,
      minLength: minLength(1),
    },
    lName: {
      required,
      minLength: minLength(1),
    },
    payersId: {
      required,
    },
  },
  data() {
    return {
      isEditAble: true,
      isRemoving: false,
      isSending: false,
      isfetching: false,
      show: true,
      caseWorkerId: "",
      items: [],
      fields: [
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "payersId",
          label: "Payer",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      payersId: "",
      search: "",
      clientid: "",
      fName: "",
      lName: "",
      idNumber: 0,
      id: "",
      status: Boolean,
      showChangePass: true,
      statusdrpdown: [
        { value: true, text: "Active" },
        { value: false, text: "Inactive" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  updated() {
    this.getCaseWorkers;
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalcaseWorker: {
      handler: function () {
        this.items = this.getCaseWorkers;
      },
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
    this.$store.dispatch("getPayersList");
  },
  computed: {
    ...mapGetters(["getCaseWorkers", "totalcaseWorker", "PayersList"]),
  },

  methods: {
    editClientinfo() {
      this.isEditAble = false;
    },
    payerNames(obj) {
      if (this.PayersList.find((x) => x.id == obj)) {
        return this.PayersList.find((x) => x.id == obj).companyName;
      } else {
        return null;
      }
    },
    sendInfo(item) {
      this.caseWorkerId = item;
    },
    create() {
      (this.id = ""),
        (this.fName = ""),
        (this.lName = ""),
        (this.payersId = "");
      this.isEditAble = false;
      this.$bvModal.show("caseWorker");
    },
    Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getCaseWorkers", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          this.items = response.output;
          this.totalItems = response.totalRow;

          this.isfetching = false;
        })
        .catch((ex) => {
          this.isfetching = false;
        });
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        (this.id = obj.id),
          (this.fName = obj.firstName),
          (this.lName = obj.lastName),
          (this.payersId = obj.payersId);
        this.isEditAble = true;
        this.$bvModal.show("caseWorker");
      }
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeCaseWorkers", {
          id: obj,
        })
        .then((response) => {
          this.items = this.getCaseWorkers;
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Case Worker has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.$bvModal.hide("confirmModal");
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });

      // this.$bvModal.show("caseWorker");
    },

    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
        if (this.id) {
          this.$store
            .dispatch("editCaseWorkers", {
              id: this.id,
              firstName: this.fName,
              lastName: this.lName,
              payersId: this.payersId,
            })
            .then((response) => {
              this.items = this.getCaseWorkers;
              this.totalItems = this.totalcaseWorker;
              this.isSending = false;
              this.$bvModal.hide("caseWorker");
              Swal.fire({
                title: "",
                text: "The Case Worker has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch((ex) => {
              this.isSending = false;

              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } else {
          this.$store
            .dispatch("saveCaseWorkers", {
              firstName: this.fName,
              lastName: this.lName,
              payersId: this.payersId,
            })
            .then(() => {
              this.fetchData();
              this.isSending = false;
              this.$bvModal.hide("caseWorker");
              Swal.fire({
                title: "",
                text: "The Case Worker has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
            })
            .catch((ex) => {
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        }
        this.fetchData();
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style lang="scss" scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
